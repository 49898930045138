<template>
    <div class="home" id="quotaReviewList">
      <div style="text-align: left" >
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
           <el-form-item label="购方名称">
              <el-input v-model="queryForm.buyersName" placeholder="请输入查询" :clearable="true"></el-input>
            </el-form-item>
            <el-date-picker value-format="yyyy-MM-dd"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          <el-form-item>
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
          <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
          <el-table-column type="index" width="50" label="" />
          
          <el-table-column prop="id" :show-overflow-tooltip="true" label="应收发票 ID" />
          <el-table-column prop="buyersAddressTel" :show-overflow-tooltip="true" label="购方地址电话" />
          <el-table-column prop="buyersBankAcc" :show-overflow-tooltip="true" label="购方银行帐号" />
          <el-table-column prop="buyersName" :show-overflow-tooltip="true" label="购方名称" />
          <el-table-column prop="buyersTaxCode" :show-overflow-tooltip="true" label="购方税号" />
          <el-table-column :show-overflow-tooltip="true" label="金额">
            <template slot-scope="scope">{{accDiv(scope.row.amount,100)}}</template>
          </el-table-column>
          
          <!-- <el-table-column prop="sallerTaxCode" :show-overflow-tooltip="true" label="销方税号" /> -->
          <el-table-column prop="taxRate" :show-overflow-tooltip="true" label="税率">
            <template slot-scope="scope">{{accDiv(scope.row.taxRate,1000000)}}</template>
          </el-table-column>
         
          <el-table-column prop="status" :show-overflow-tooltip="true"  label="状态">
            <template slot-scope="scope">
              <div v-for="(item, index) in itemList" v-bind:key="index" >
                <div v-if="item.datavalue == scope.row.status">
                  {{  item.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" :show-overflow-tooltip="true" label="创建时间" />
          <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
                <el-button type="text" size="small" @click="quotaReviewdiaLog(scope.row)" style="color: #09BF88">审核</el-button>
            </template>
          </el-table-column>
        </el-table>
       <el-pagination
          id="page"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          @prev-click="currentChange"
          @next-click="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <el-dialog title="发票审核" class="quotaReviewDialag" id="clear_layout" :visible.sync="dialogVisibledetails" @close="$refs['dialogform'].resetFields();">
        <el-form id="dialogform" :model="dialogform" :rules="rules" ref="dialogform" > 
          <el-form-item label="发票审核:" prop="radio" >
            <el-radio-group v-model="dialogform.radio">
              <el-radio  label="2">审核通过</el-radio>
              <el-radio  label="3">驳回审核</el-radio>
          </el-radio-group>
              
          </el-form-item>
          <el-form-item label="备注:" prop="desc">
              <el-input v-model="dialogform.remark" type="textarea"  ></el-input>
        </el-form-item>
        </el-form>
        
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibledetails = false;"  >取 消</el-button>
          <el-button type="primary" @click="submitForm('dialogform')">确 定</el-button>
        </div>
      </el-dialog>

    </div>
  </template>
  <script>
  
  import XLSX from "xlsx"
  
  import validateUtils from '@/util/validateUtils'
  import { re } from 'mathjs'
  import { Progress } from "element-ui"
  import { title } from "process"
  
  export default {
      data() {
        return {
        rules: {
           radio: [
            { required: true, message: '请选择发票审核', trigger: 'change' }
          ],
        },
          // 0为汇总
          type: 0,
          queryForm: {
            time:[],
            status: 3,
            flag: 3,
            buyersName:'',
            pageNo: 1,
            pageSize: 10
          },
          total:0,
          // 这个站点对象集合
          fullscreenLoading: false,
          updateDialogVisible:false,
          dialogVisibledetails:false,
          dialogform: {
            radio: null,
            remark: null,
          },
          groupList: [],
          options: [],
          // 状态字典列表
          summaryStateList:[],
          // 以表示排列的数组
          summaryArray: null,
          // 表格对象
          tableData: [],
          // input: '',
          updateForm: {
            lineAmount:'',
            price:'',
            quantity:''
          },
          formInline: {
            user: '',
            region: ''
          },
          dialogFormVisible: false,
          innerVisible: false,
          fileList: [],
          queryParams: {
          pageNo: 1,
          pageSize: 10,
          code: "",
          name: "",
        },
        changeStateParams:{
          arInvoiceId: '',
          status: null,
          sessionId: ''
        },
        itemList: [],
        diction: {
                    code: "invoiceStatus",
                },
        }
      },
      computed: {

      },
      watch: {
        queryFormGetGroupIds(newVal, oldVal){
          this.queryForm.groupId = newVal;
        }
      },
      // 实例创建完成后调用
  
      mounted: function(){
                  this.findDictionariesItemList(this.diction);
          },
      async created(){
          let flag = this;
        // 获取分組 信息
        await this.api.platformApi.getGroupTree({}).then((res) => {
          res = res.data
          if(res.code == 200){
            flag.options = res.data;
          //   flag.loading = false;
          }
        })
        this.loadTable();
      },
      methods: {
         // 分页查看薪资信息列表
          async loadTable(){
            console.log(this.queryForm);
              this.loading = true;
              let flag = this;
              //getStaffList
              await this.api.invoiceApi.invoiceApproval(this.queryForm).then((res) => {
                  res = res.data
                  console.log("----发票列表-----",res);
                  // flag.loading = false;
                  if(res.code == 200){
                      flag.tableData = res.data.records;
                      flag.total = res.data.total
                      console.log(flag.tableData);
                  }else{
              this.tableData =  null;
              this.total = 0;
              this.$message.error(res.msg);
            }
              })
          },
        toFloat(val){
              if(this.isEntity(val)){
                  return '';
              }
              return parseFloat(val)/10000+"%";
          },
        // 分页器 size改变是触发
         //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
  
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        // 改变状态
       async quotaReview(){
            if(this.dialogform.radio == 2){
            this. dialogVisibledetails = false;
            var params = {
                arInvoiceId: this.arInvoiceId,
                reviewRemarks: this.dialogform.remark,
                status: this.dialogform.radio
            };
            console.log("---------",params);
                await this.api.invoiceApi.approval(params).then(res =>{
                    console.log("----------",res);
                    if(res.data.code==200){
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.loadTable();
                    }else{
                    this.$message.error('操作失败！');
                    this.loadTable();
                    }
                });
            }else if (this.dialogform.radio == 3){
                this. dialogVisibledetails = false;
                var params = {
                    arInvoiceId: this.arInvoiceId,
                    reviewRemarks: this.dialogform.remark,
                    status: 4,
                };
                console.log("---------",params);
                    await this.api.invoiceApi.approval(params).then(res =>{
                    console.log("----------",res);
                    if(res.data.code==200){
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                    this.loadTable();
                    }else{
                    this.$message.error('操作失败！');
                    this.loadTable();
                    }
                    });
            }
          
        },
        quotaReviewdiaLog(row) {
            this.arInvoiceId=row.id;
			      this.dialogVisibledetails = true;
		    },
        submitForm(formName) {
            console.log(formName)
            this.$refs['dialogform'].validate((valid) => {
            if (valid) {
                    console.log("22222222")
                this.quotaReview();
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
        // 重置
        onReset(){
          this.queryForm.time=[];
          this.status= [1,2,6]
          this.queryForm.buyersName=''
        },
        async findDictionariesItemList(param) {
                  let response = await this.api.platformApi.findDictionariesItemList(param);
                  console.log("数据字典", response);
                  this.itemList = response.data.data;
              },
        tableHeader(column) {
          return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
        //除法函数
        accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
      },
  }
  </script>
  
  <style scoped>
    .submitMsg{
      color: #09BF88;
      font-size: 1.5rem;
      
    }
    .submitButton{
      background-color: #09BF88;
      border: none;
    }
    .submitButton:hover{
      background-color: #3ACCA0;
    }
    .repealMsg{
      color: #FFAC1D;
      font-size: 1.5rem;
    }
    .repealButton{
      background-color: #FFAC1D;
      border: none;
    }
    .repealButton:hover{
      background-color: #FFBD4A;
    }
  
    .cancellationMsg{
      color: #F78969;
      font-size: 1.5rem;
    }
    .cancellationButton{
      background-color: #F78969;
      border: none;
    }
    .cancellationButton:hover{
      background-color: #F789;
    }
  /* -------------------------------------- */
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  .el-drawer.rtl {
    overflow: scroll;
  }
  
  .updateForm{
    width: 90%;
    margin: 0 auto;
  }
  .parseXlsxBottom{
    text-align: end;
    padding: 20px;
  }
  </style>
  
  
  